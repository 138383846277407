import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}

      <svg width="100%" height="100%" viewBox="0 0 2 2" xmlns="http://www.w3.org/2000/svg">
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          {' '}
          <path
            d="M1.05114 0.474432C1.03551 0.42661 1.01491 0.383759 0.989347 0.345881C0.964252 0.307528 0.934186 0.274858 0.899148 0.247869C0.864583 0.220881 0.825284 0.200284 0.78125 0.18608C0.737216 0.171875 0.68892 0.164773 0.636364 0.164773C0.550189 0.164773 0.471828 0.187027 0.401278 0.231534C0.330729 0.276042 0.274621 0.341619 0.232955 0.428267C0.191288 0.514915 0.170455 0.621212 0.170455 0.747159C0.170455 0.873106 0.191525 0.979403 0.233665 1.06605C0.275805 1.1527 0.33286 1.21828 0.40483 1.26278C0.476799 1.30729 0.557765 1.32955 0.647727 1.32955C0.731061 1.32955 0.804451 1.31179 0.867898 1.27628C0.931818 1.24029 0.981534 1.18963 1.01705 1.12429C1.05303 1.05848 1.07102 0.981061 1.07102 0.892045L1.125 0.903409H0.6875V0.747159H1.24148V0.903409C1.24148 1.0232 1.21591 1.12737 1.16477 1.21591C1.11411 1.30445 1.04403 1.37311 0.954545 1.42188C0.86553 1.47017 0.763258 1.49432 0.647727 1.49432C0.518939 1.49432 0.405777 1.46402 0.308239 1.40341C0.211174 1.3428 0.135417 1.25663 0.0809659 1.14489C0.0269886 1.03314 0 0.900568 0 0.747159C0 0.632102 0.0153883 0.528646 0.0461648 0.43679C0.0774148 0.34446 0.121449 0.265862 0.178267 0.200994C0.235085 0.136127 0.30232 0.086411 0.379972 0.0518466C0.457623 0.0172822 0.543087 0 0.636364 0C0.713068 0 0.784564 0.0116004 0.850852 0.0348011C0.917614 0.0575284 0.977036 0.0899621 1.02912 0.132102C1.08168 0.173769 1.12547 0.223722 1.16051 0.28196C1.19555 0.339725 1.2197 0.403883 1.23295 0.474432H1.05114Z"
            fill="url(#BG1)"
          />
        </g>
        <defs>
          <linearGradient id="BG1" x1="0.620739" y1="0" x2="0.620739" y2="1.49432" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="0.620739" y1="0" x2="0.620739" y2="1.49432" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" y1="0" x2="0.620739" y2="1.49432" gradientUnits="userSpaceOnUse">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}
