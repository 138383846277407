import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setAuthorizationToken } from '../utils/setAuthorizationToken';
import { userAPI } from '../api/userAPI';
import { appLoading, setMessage, setIsAuthenticated } from './appSlice';

const manageLocalStorage = (storageName, liveData) => {
  const newArray = [];
  newArray.push(liveData);
  if (localStorage.getItem(storageName) === null) {
    localStorage.setItem(storageName, JSON.stringify([]));
  }
  const existed = JSON.parse(localStorage.getItem(storageName));
  if (existed && existed.length > 20) {
    localStorage.removeItem(storageName);
    localStorage.setItem(storageName, JSON.stringify([...newArray]));
    return;
  }

  localStorage.setItem(storageName, JSON.stringify([...existed, ...newArray]));
};

// async thunk request to User Request
export const getUserRequest = createAsyncThunk(
  'user/getUserRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.getCpUser();
      dispatch(setUserDetail(data));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to Login
export const loginRequest = // eslint-disable-next-line
  createAsyncThunk('user/loginRequest', async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const getToken = await userAPI.loginRequest(userdata);
      localStorage.setItem('userPermit', JSON.stringify(getToken.accessToken));
      setAuthorizationToken(getToken.accessToken);
      dispatch(setIsAuthenticated(true));
      dispatch(getUserRequest());
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  });

// async thunk request to User Log OUT
// eslint-disable-next-line
export const userLogoutRequest = createAsyncThunk(
  'user/userLogoutRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.logoutCall();
      dispatch(setMessage(data.message));
      localStorage.removeItem('userPermit');
      setAuthorizationToken(false);
      dispatch(setIsAuthenticated(false));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to User Log OUT
export const changePasswordRequest = createAsyncThunk(
  'user/changePasswordRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.changePassword(userdata);
      dispatch(setMessage(data.message));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to User Update Nominee
export const updateNomineeRequest = createAsyncThunk(
  'user/updateNomineeRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      const data = await userAPI.updateNominee(userdata);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to User set PIN
export const setPinRequest = createAsyncThunk(
  'user/setPinRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      const data = await userAPI.setPIN(userdata);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to Partner Account Balance
// eslint-disable-next-line
export const getBalanceRequest = createAsyncThunk(
  'user/getBalanceRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.getMyBalance();
      dispatch(setPartnerBalance(data.balance));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to User PORTFOLIO
// eslint-disable-next-line
export const portfolioRequest = createAsyncThunk(
  'user/portfolioRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.totalPortfolio();
      dispatch(setPortfolio(data));
      dispatch(setPartnerBalance(data.equity));
      dispatch(setProfit(data.profit));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to Deposit History
// eslint-disable-next-line
export const depositHistoryRequest = createAsyncThunk(
  'user/depositHistoryRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.depositHistory();
      dispatch(setDepositHistory(data.depositHistory));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to Withdraw History
export const makeWithdrawlRequest = createAsyncThunk(
  'user/makeWithdrawlRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      const data = await userAPI.sendWithdrwalRequest(userdata);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to Close All trades
export const closeAllTradesRequest = createAsyncThunk(
  'user/closeAllTradesRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      const data = await userAPI.closeAll(userdata);
      dispatch(setMessage(data.message));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to Withdraw History

export const withdrawHistoryRequest = createAsyncThunk(
  'user/withdrawHistoryRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.withdrawHistory();
      dispatch(setWithdrawHistory(data.withdrawHistory));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to get my transactions list

export const getPartnerTransactionsRequest = createAsyncThunk(
  'user/getPartnerTransactionsRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.myTransactions();
      dispatch(setPartnerTransactions(data.transactions));
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// async thunk request to get my transactions list

export const getLiveProfitLossRequest = createAsyncThunk(
  'user/getLiveProfitLossRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      dispatch(appLoading(true));
      const data = await userAPI.liveProfit();
      dispatch(setLiveProfit(data));
      const balance = data.equity + data.profit;
      manageLocalStorage('liveProfitChart', data.liveProfitLoss);
      manageLocalStorage('currentBalance', balance);
      manageLocalStorage('drawDown', data.drawDown);
      manageLocalStorage('usedMargin', data.usedMargin);
      dispatch(appLoading(false));
    } catch (error) {
      if (error.response) {
        dispatch(appLoading(false));
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

// get chart

export const getChartDataRequest = createAsyncThunk(
  'user/getChartDataRequest',
  // eslint-disable-next-line
  async (userdata, { dispatch, rejectWithValue }) => {
    try {
      const data = await userAPI.getChart();
      dispatch(setSettlementChart(data.settlements));
      dispatch(setWithdrawlChart(data.withdrawls));
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

const initialState = {
  userDetails: {},
  portfolio: {},
  hasError: '',
  profit: 0,
  depositHistory: [],
  withdrawHistory: [],
  partnerBalance: 0,
  myTransactions: [],
  liveProfit: {},
  withdrawlChart: [],
  settlementChart: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetail: (state, action) => {
      state.userDetails = action.payload;
    },
    setPortfolio: (state, action) => {
      state.portfolio = action.payload;
    },
    setDepositHistory: (state, action) => {
      state.depositHistory = action.payload;
    },
    setWithdrawlChart: (state, action) => {
      state.withdrawlChart = action.payload;
    },
    setSettlementChart: (state, action) => {
      state.settlementChart = action.payload;
    },
    setWithdrawHistory: (state, action) => {
      state.withdrawHistory = action.payload;
    },
    setPartnerBalance: (state, action) => {
      state.partnerBalance = action.payload;
    },
    setPartnerTransactions: (state, action) => {
      state.myTransactions = action.payload;
    },
    setProfit: (state, action) => {
      state.profit = action.payload;
    },
    setLiveProfit: (state, action) => {
      state.liveProfit = action.payload;
    },
    clearHasError: (state) => {
      state.hasError = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUserRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(loginRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(userLogoutRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(changePasswordRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(portfolioRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(depositHistoryRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(withdrawHistoryRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(getLiveProfitLossRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(closeAllTradesRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(updateNomineeRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(makeWithdrawlRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
    builder.addCase(setPinRequest.rejected, (state, action) => {
      state.hasError = action.payload;
    });
  }
});

export const {
  setUserDetail,
  setPortfolio,
  setDepositHistory,
  setWithdrawHistory,
  setPartnerBalance,
  setPartnerTransactions,
  clearHasError,
  setProfit,
  setLiveProfit,
  setWithdrawlChart,
  setSettlementChart
} = userSlice.actions;

export default userSlice.reducer;
