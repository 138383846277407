import axios from 'axios';

const baseURL = `${process.env.REACT_APP_BASE_URL}`;

export const userAPI = {
  loginRequest: async (loginData) => axios.post(`${baseURL}/login`, loginData).then((res) => res.data),
  getCpUser: async () => axios.get(`${process.env.REACT_APP_BASE_URL}/getuser`).then((res) => res.data),
  logoutCall: async () => axios.get(`${baseURL}/logout`).then((res) => res.data),
  getChart: async () => axios.get(`${baseURL}/get-chart-data`).then((res) => res.data),
  changePassword: async (dt) => axios.put(`${baseURL}/change-password`, dt).then((res) => res.data),
  totalPortfolio: async () => axios.get(`${baseURL}/get-account-value`).then((res) => res.data),
  depositHistory: async () => axios.get(`${baseURL}/get-deposit-history`).then((res) => res.data),
  withdrawHistory: async () => axios.get(`${baseURL}/get-withdraw-history`).then((res) => res.data),
  liveProfit: async () => axios.get(`${baseURL}/live-profit-loss`).then((res) => res.data),
  myTransactions: async () => axios.get(`${baseURL}/last-5-transactions`).then((res) => res.data),
  getMyBalance: async () => axios.get(`${baseURL}/get-account-balance`).then((res) => res.data),
  sendWithdrwalRequest: async (profit) => axios.post(`${baseURL}/withdraw-request`, profit).then((res) => res.data),
  closeAll: async (pass) => axios.post(`${baseURL}/close-all-trade-now`, pass).then((res) => res.data),
  updateNominee: async (pass) => axios.post(`${baseURL}/update-nominee-request`, pass).then((res) => res.data),
  setPIN: async (pass) => axios.post(`${baseURL}/first-time-pin-request`, pass).then((res) => res.data)
};
